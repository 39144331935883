<template>
    <!-- 租赁订单 -->
    <div>
        <!-- 搜索栏 & 标签页 -->
        <order-search
            :start="startDate"
            :end="endDate"
            @handle-start="handleStart"
            @handle-end="handleEnd"
            @handle-search="getData">
        </order-search>
        <order-tabs
            v-model="tab"
            :tabs-list="tabList"
            @tab-click="getData">
        </order-tabs>
        <!-- 表格 -->
        <center-order-table
            table-title="租赁订单"
            :table-list="orderTableList">
            <!-- 价格 -->
            <template #price="{price}">
                <div>{{"¥ "+ price}}</div>
            </template>
            <!-- 实付款 -->
            <template #payment="{price}">
                <div>{{"¥ "+ price}}</div>
            </template>
            <!-- 交易状态 -->
            <template #status="{status, id}">
                <div>{{status | ostatus}}</div>
                <router-link
                    :to="{
                        name: 'clothesPay',
                        query:{id}
                    }">
                    订单详情
                </router-link>
            </template>
            <!-- 操作 -->
            <template #handle="{row}">
                <!-- 我的服饰 -->
                <div>
                    <!-- 同意 -->
                    <a href="javascript:;"
                        class="center-lease-handle"
                        v-if="row.orderStatus==1"
                        @click="handleStatus(row.orderID, 2)">
                        同意
                    </a>
                    <!-- 拒绝 -->
                    <a href="javascript:;"
                        class="center-lease-handle"
                        v-if="row.orderStatus==1"
                        @click="handleStatus(row.orderID, 3)">
                        拒绝
                    </a>
                    <!-- 借出 -->
                    <a href="javascript:;"
                        class="center-lease-handle"
                        v-if="row.orderStatus==2"
                        @click="handleStatus(row.orderID, 4)">
                        送出
                    </a>
                    <!-- 收回
                    <a href="javascript:;"
                        class="center-lease-handle"
                        v-if="row.orderStatus==4"
                        @click="handleStatus(row.orderID, 5)">
                        收回
                    </a>-->
                    <a href="javascript:;"
                        class="center-lease-handle"
                        v-if="row.orderStatus==5"
                        @click="handleStatus(row.orderID, 9)">
                        确认无破损
                    </a>
                </div>
            </template>
        </center-order-table>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import order from '@/mixins/centerOrder'

export default {
    filters:{
        // 订单状态过滤器
        ostatus(val){
            switch(val){
                case 0:
                    return "待付款";
                    break;
                case 1:
                    return "待接单";
                    break;
                case 2:
                    return "已同意";
                    break;
                case 3:
                    return "已拒绝";
                    break;
                case 4:
                    return "租赁中";
                    break;
                case 5:
                    return "确认无破损";
                    break;
                case 9:
                    return "已完成";
                    break;
                case -1:
                    return "取消";
                    break;
            }
        },
    },
    mixins: [ order ],
    data() {
        return {
            tabList: [
                {
                    value: "-2",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "待确认",
                },
                {
                    value: "2",
                    label: "已同意",
                },
                {
                    value: "3",
                    label: "已拒绝",
                },
                {
                    value: "4",
                    label: "租赁中",
                },
                {
                    value:"5",
                    label:"确认无破损",
                },
                {
                    value: "9",
                    label: "已完成",
                },
                {
                    value: "-1",
                    label: "已取消",
                }
            ]
        }
    },
    computed: mapState({
        user_id: state => state.user.user_id,
        orderTableList: state => state.order.orderTableList
    }),
    methods: {
        ...mapMutations([
            'handleLoading',
            'setCenterOrder'
        ]),
        ...mapActions([
            'getCenterOrder',
            'handleOrderStatus'
        ]),
        getParams() {
            let userID = this.user_id,
                fromTime = this.startDate,
                toTime = this.endDate,
                orderStatus = this.tab == '-2'? '': this.tab;
            return this.$qs.stringify({
                userID,
                fromTime,
                toTime,
                orderStatus,
                type: 1
            })
        },
        getData() {
            let url = 'usercenter/leaseorder',
                params = this.getParams();
            this.getCenterOrder({
                url,
                params
            })
        },
        getStatusParams(orderID, status) {
            let status_name = '',
                userID = this.user_id,
                url = '/usercenter/leaseOrderStatus',
                params = this.$qs.stringify({
                    userID,
                    orderID,
                    status
                });
            switch (status) {
                case 2:
                    status_name = '同意';
                    break;
                case 3:
                    status_name = '拒绝';
                    break;
                case 4:
                    status_name = '借出';
                    break;
                case 5:
                    status_name = '收回';
                case 9:
                    status_name = '完成';
                    break;
                case -1:
                    status_name = '取消';
                    break;
            }
            return {
                status_name,
                url,
                params
            }
        },
        handleDelivery(orderID, status) { // 改变订单状态
          let traNo = ''
          this.$prompt('请输入物流单号', '物流单号', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({ value }) => {
            traNo = value;
            if(!value){
              this.$message({
                showClose: true,
                message: "您还未输入物流单号!",
                type: "error"
              })
              return
            }
            return this.handleSendTraNo({
              orderID,
              traNo,
              status
            })
          }).then(res => {
            console.log(322434)
            this.$message({
              showClose: true,
              message: res.data.message,
              type: res.data.code == 200 ? 'success' : 'error'
            })
            this.getData()
          }).catch(() => {})
        },
        handleStatus(orderID, status){ // 改变订单状态
            let {
                status_name,
                url,
                params
            } = this.getStatusParams(orderID, status);

            this.$confirm(`是否${status_name}该订单？`, '提示' ,{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleLoading(true);

                return this.handleOrderStatus({
                    url,
                    params
                })
            }).then(res => {
                let code = res.data.code == 200;

                this.$message({
                    showClose: true,
                    message: res.data.message,
                    type: code? 'success': 'error'
                })
                code && this.getData();
            }).catch(() => {
            }).finally(() => {
                this.handleLoading(false);
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.getData();
        })
    },
    beforeRouteLeave (to, from, next) {
        this.setCenterOrder([]);
        next();
    }
}
</script>

<style scoped>
/* 表格 */
.center_lease_table{
    border-top: 1px solid #F2F6FC;
}
/* 按钮样式 */
.center_lease_table_btn_box{
    margin-left: 10px;
}
/* 详情按钮 */
.center_lease_table_btn_detail{
    color: #303133;
}
/* 拒绝按钮 */
.center_lease_table_btn_refuse{
    color: #F56C6C;
}
/* 同意按钮 */
.center_lease_table_btn_agree{
    color: #67C23A;
}
.center-lease-handle{
    display: block;
}
.center-lease-handle+.center-lease-handle{
    margin-top: 10px;
}
</style>
